import React from "react";
import Image from "../image";
import { Link } from "react-router-dom";
function SearchResultsLocation(props) {
  const { lang, loading, location } = props;
  return (
    <Link
      className="col-xl-6 search-location-wrap"
      to={
        global.getRoot() +
        "/" +
        location.group +
        "/" +
        location.subgroup +
        "/" +
        location.stub
      }
    >
      <div className="search-location-image">
        <Image imageSrc={location.path + "small/" + location.filename} />
      </div>
      <div className="search-location-content">
        <div className="search-location-name">{location["name_" + lang]}</div>
        <div className="search-location-text">
          {location["text_" + lang].split(" ").slice(0, 15).join(" ") + "..."}
        </div>
      </div>
    </Link>
  );
}

export default SearchResultsLocation;
