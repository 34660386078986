import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";
import Image from "./image";
function NewsItem(props) {
  const { loading, newsItem, locale, lang, h2, h3 } = props;

  function formatDate(date) {
    let options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  function formatDatetime(date) {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  return (
    <>
      {loading && (
        <div className="sidebar-news">
          <div className="sidebar-news-inner">
            <div className="sidebar-news-date d-block">
              <Placeholder animation="glow">
                <Placeholder xs={3} size="lg" />
              </Placeholder>
            </div>
            <div className="sidebar-news-title d-block">
              <Placeholder animation="glow">
                <Placeholder xs={12} size="xl" />
              </Placeholder>
            </div>
          </div>
          <div className="sidebar-news-image d-block">
            <Placeholder animation="glow">
              <Placeholder style={{ width: "100%", height: "100%" }} />
            </Placeholder>
          </div>
        </div>
      )}
      {!loading && (
        <Link
          to={global.getRoot() + "/vesti/" + newsItem.stub}
          className="sidebar-news"
        >
          <div className="sidebar-news-inner">
            <div className="sidebar-news-date">
              {formatDatetime(newsItem.published + "000")}
            </div>
            {h2 && (
              <h2 className="sidebar-news-title">
                {newsItem["title_" + lang]}
              </h2>
            )}
            {h3 && (
              <h3 className="sidebar-news-title">
                {newsItem["title_" + lang]}
              </h3>
            )}
            {!h2 && !h3 && (
              <div className="sidebar-news-title">
                {newsItem["title_" + lang]}
              </div>
            )}
          </div>
          <div className="sidebar-news-image">
            <Image
              imageSrc={newsItem.path + "small/" + newsItem.filename}
              imageAlt={newsItem["photo_text_" + lang]}
            />
          </div>
          {(parseInt(newsItem.published) + 86400) * 1000 > Date.now() &&
            !global.seen(newsItem.news_id) && (
              <div className="new-marker-small news-marker">
                {"NOVO".translate()}
              </div>
            )}
        </Link>
      )}
    </>
  );
}

export default NewsItem;
