import React from "react";
import { Link } from "react-router-dom";
import Image from "../image";
import Placeholder from "react-bootstrap/Placeholder";
import Collapse from "react-bootstrap/Collapse";

function Group(props) {
  const { group, onToggleSubmenu, index, lang, loading, h2, h3 } = props;
  return (
    <div className="col-md-12 g-3 cursor-pointer">
      <div onClick={() => onToggleSubmenu(index)} className="group-item">
        <div className="row ms-0 me-0 w-100">
          <div className="col ps-0 pe-0">
            <div className="group-item-inner">
              <div className="group-item-inner-text">
                {h2 && (
                  <h2 className="group-item-inner-text-title">
                    {loading && (
                      <Placeholder animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    )}
                    {!loading && group && group["name_" + lang]}
                  </h2>
                )}
                {h3 && (
                  <h3 className="group-item-inner-text-title">
                    {loading && (
                      <Placeholder animation="glow">
                        <Placeholder xs={12} />
                      </Placeholder>
                    )}
                    {!loading && group && group["name_" + lang]}
                  </h3>
                )}
                <div className="group-item-inner-text-text">
                  {loading && (
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  )}
                  {!loading && group && group["text_" + lang]}
                </div>
              </div>
              <div className="group-item-inner-image">
                {loading && <div style={{ width: "41px", height: "41px" }} />}
                {!loading && group && (
                  <Image
                    imageUi={true}
                    imageSrc={group.filename}
                    imageAlt={group["name_" + lang]}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {!loading && group && (
          <Collapse in={group.subgroup_visible}>
            <div className="w-100">
              <ul className="subgroup-list">
                {group.subgroups.map((subgroup) => (
                  <li className="subgroup-item" key={subgroup.subgroup_id}>
                    <Link
                      to={
                        global.getRoot() +
                        "/" +
                        group.stub +
                        "/" +
                        subgroup.stub
                      }
                    >
                      {subgroup["name_" + lang] + " (" + subgroup.count + ")"}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
}

export default Group;
