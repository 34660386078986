import React, { useState, useEffect } from "react";
import SubHeader from "../components/subHeader";
import PromoApp from "../components/promoApp";
import SeoTags from "../components/seoTags";
import Header from "../components/header";

function Contact(props) {
  const [loading, setLoading] = useState(true);
  const { lang, locale } = props;
  const [data, setData] = useState([]);
  const [seo, setSeo] = useState([]);

  global.setLang(lang);

  useEffect(() => {
    setLoading(true);
    const requestOptionsSearchSeo = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        page: "contact",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_seo.php", requestOptionsSearchSeo)
      .then((response) => response.json())
      .then((responseData) => {
        setSeo(responseData);
      });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_city_data.php", requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <SeoTags
        title={!loading && seo["seo_title_" + lang]}
        description={!loading && seo["seo_description_" + lang]}
        type="website"
        locale={locale}
        lang={lang}
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo={global.getRoot() + "/"}
              firstLine={global.data()["app_name_" + lang]}
              secondLine={"Kontakt".translate()}
              h1
              icon={<i className="bi bi-geo-alt me-2 icon-primary"></i>}
            />
          </div>
        </div>
        <div className="container-md mt-5 mb-5">
          <div className="row">
            <div className="col-md-8">
              <div className="row mt-4">
                <div className="col-md">
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        src={
                          process.env.REACT_APP_CDN +
                          global.data().alias +
                          "/static/" +
                          "just-logo.png"
                        }
                      />
                    </div>
                    <div className="col-md-9 company-title">
                      <div>
                        <h2>
                          {!loading && data && data.city["app_name_" + lang]}
                        </h2>
                      </div>
                      <div>
                        {!loading && data && data.city["description_" + lang]}
                      </div>
                      <div>
                        {!loading && data && data.city["publisher_" + lang]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 contact-page">
              {!loading && data.phone && data.phone.length > 0 && (
                <h2 className="title mb-1 mt-3">{"Telefon".translate()}</h2>
              )}
              {!loading &&
                data.phone &&
                data.phone.map((phoneItem) => (
                  <div key={phoneItem.meta_id}>{phoneItem.meta_value}</div>
                ))}
              {!loading && data.email && data.email.length > 0 && (
                <h2 className="title mb-1 mt-3">{"E-mail".translate()}</h2>
              )}
              {!loading &&
                data.email &&
                data.email.map((emailItem) => (
                  <div>
                    <a
                      key={emailItem.meta_id}
                      href={"mailto:" + emailItem.meta_value}
                    >
                      {emailItem.meta_value}
                    </a>
                  </div>
                ))}
              {!loading && data.web && data.web.length > 0 && (
                <h2 className="title mb-1 mt-3">{"Web".translate()}</h2>
              )}
              {!loading &&
                data.web &&
                data.web.map((webItem) => (
                  <div>
                    <a
                      key={webItem.meta_id}
                      href={"https://" + webItem.meta_value}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {webItem.meta_value}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <PromoApp lang={lang} />
      </main>
    </>
  );
}

export default Contact;
