import React from "react";
import Image from "../image";
import Placeholder from "react-bootstrap/Placeholder";

function SingleObjectMainPhoto(props) {
  const { object, lang, loading } = props;
  return (
    <div className="col-md">
      <div className="single-object-header">
        {loading && (
          <Placeholder animation="glow">
            <Placeholder style={{ width: "100%", height: "100%" }} />
          </Placeholder>
        )}
        {!loading && object && (
          <>
            <Image
              imageSrc={object.path + "large/" + object.filename}
              imageAlt={object["photo_text_" + lang]}
            />
            {object.credit !== "" && (
              <div className="photo-source">
                {"Foto:".translate() + " " + object.credit}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SingleObjectMainPhoto;
