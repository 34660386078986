import React from "react";
import Rating from "./rating";
import { Link } from "react-router-dom";
import Distance from "./distance";
import Name from "./name";
import Image from "../image";
import Placeholder from "react-bootstrap/Placeholder";

function ObjectListItem(props) {
  const { object, lang, loading } = props;
  return (
    <>
      {loading && (
        <div className="col-md-6 g-3">
          <div className="object-list-item">
            <Placeholder animation="glow">
              <Placeholder style={{ width: "100%", height: "100%" }} />
            </Placeholder>
            <Rating rating="0" />
            <Distance distance="" loading={loading} />
            <Name name="" loading={loading} />
          </div>
        </div>
      )}
      {!loading && object && (
        <Link to={object.stub} className="col-md-6 g-3">
          <div className="object-list-item">
            <Image
              imageSrc={object.path + "medium/" + object.filename}
              imageAlt={object["photo_text_" + lang]}
            />
            <Rating rating={object.rating} />
            <Distance distance={object.distance} />
            <Name name={object["name_" + lang]} />
          </div>
        </Link>
      )}
    </>
  );
}

export default ObjectListItem;
