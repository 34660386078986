import React, { useCallback } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import Image from "./image";
import { useNavigate } from "react-router-dom";

function SubHeader(props) {
  const { title, loading, h2, image, className, linkTo, icon } = props;
  const navigate = useNavigate();
  let additionalClass;
  if (className) {
    additionalClass = " " + className;
  } else {
    additionalClass = "";
  }
  const handleOnClick = useCallback(
    () => navigate(linkTo, { replace: true }),
    [navigate]
  );

  return (
    <div
      onClick={handleOnClick}
      className={
        linkTo
          ? "row g-0 subheader-wrap-link" + additionalClass
          : "row g-0 subheader-wrap" + additionalClass
      }
    >
      <div className="col-md">
        <div className="header-inner">
          <div className="header-inner-left">
            {h2 && (
              <h2
                className={
                  "subheader-title mb-0 p-2" + (loading ? " d-block" : "")
                }
              >
                {icon && icon}
                {!loading && image && (
                  <Image imageUi={true} imageSrc={image} imageWidth={25} />
                )}
                {loading && (
                  <Placeholder
                    animation="glow"
                    className={loading ? "ms-2" : ""}
                  >
                    <Placeholder xs={6} size="sm" />
                  </Placeholder>
                )}
                {!loading && title && title}
              </h2>
            )}
            {!h2 && (
              <div
                className={
                  "subheader-title mb-0 p-2" + (loading ? " d-block" : "")
                }
              >
                {icon && icon}
                {!loading && image && (
                  <Image imageUi={true} imageSrc={image} imageWidth={25} />
                )}
                {loading && (
                  <Placeholder
                    animation="glow"
                    className={loading ? "ms-2" : ""}
                  >
                    <Placeholder xs={6} size="sm" />
                  </Placeholder>
                )}
                {!loading && title && title}
              </div>
            )}
          </div>
          {linkTo && (
            <div className="header-inner-right pe-2">
              <i className="bi bi-caret-right-fill green"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
