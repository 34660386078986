import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import PrivacyPolicyEnglish from "../components/privacyPolicyEnglish";
import PrivacyPolicySerbian from "../components/privacyPolicySerbian";
import Collapse from "react-bootstrap/Collapse";
import CustomTooltip from "../components/tooltip";

function CommentModal(props) {
  const {
    show,
    onCloseComment,
    onSubmitComment,
    commentError,
    commentLoading,
    lang,
  } = props;
  const inputName = useRef("");
  const inputEmail = useRef("");
  const inputComment = useRef("");
  const inputPrivacyPolicy = useRef("");
  const commentForm = useRef();
  const [validated, setValidated] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);

  const validateForm = () => {
    const form = commentForm.current;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      onSubmitComment(
        inputName.current.value,
        // inputEmail.current.value,
        inputComment.current.value,
        inputPrivacyPolicy.current.checked
      );
      setValidated(false);
    }
  };

  const closeModal = () => {
    setValidated(false);
    onCloseComment();
    setShowPolicy(false);
  };

  function privacyPolicyToggle(e) {
    e.preventDefault();
    setShowPolicy(!showPolicy);
  }

  return (
    <Modal
      show={show}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="custom-modal-title">
          <i className="bi bi-chat-left-text-fill icon-primary"></i>
          {"Ostavi komentar".translate()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          ref={commentForm}
          autoComplete="off"
        >
          <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Label className="form-label-addon">
              {"Vaše ime".translate()}
              {/* <CustomTooltip
                id="ime"
                placement="left"
                text={"Ovaj lični podatak će biti vidljiv drugim korisnicima nakon objavljivanja.".translate()}
                trigger={<i className="bi bi-eye color-red"></i>}
              /> */}
            </Form.Label>
            <Form.Control required type="text" ref={inputName} />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="validationCustom02">
            <Form.Label className="form-label-addon">
              {"Vaša mejl adresa".translate()}
              <CustomTooltip
                id="ime"
                placement="left"
                text={"Ovaj lični podatak je zaštićen i neće biti vidljiv drugim korisnicima nakon objavljivanja.".translate()}
                trigger={<i className="bi bi-eye-slash color-green"></i>}
              />
            </Form.Label>
            <Form.Control required type="email" ref={inputEmail} />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="validationCustom02">
            <Form.Label>{"Vaš komentar".translate()}</Form.Label>
            <Form.Control as="textarea" required rows={4} ref={inputComment} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              required
              label={
                <>
                  {"Pročitao(la) sam i slažem se sa".translate() + " "}
                  <a
                    href=""
                    className="classic-link"
                    onClick={(e) => privacyPolicyToggle(e)}
                  >
                    {"Politikom privatnosti".translate()}
                  </a>
                </>
              }
              ref={inputPrivacyPolicy}
            />
          </Form.Group>
        </Form>
        <Collapse in={showPolicy}>
          <div>
            <div className="policy-holder">
              {lang === "sr" && <PrivacyPolicySerbian />}
              {lang === "en" && <PrivacyPolicyEnglish />}
            </div>
          </div>
        </Collapse>
        {commentError && <div className="error-form-text">{commentError}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={validateForm}>
          {"Pošalji".translate()}
          {commentLoading ? (
            <i className="ms-1 fa fa-spinner fa-spin"></i>
          ) : null}
        </Button>
        <Button variant="danger" onClick={closeModal}>
          {"Zatvori".translate()}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CommentModal;
