import React from "react";
function CookiePolicyCyrilic() {
  return (
    <>
      <h1>Политика о колачићима</h1>
      <p>Верзија: 1, Важи од: 01.01.2023.</p>
      <p>
        Колачићи су мали текстуални фајлови који се чувају на Вашем рачунару или
        мобилном уређају обезбеђујући ефикасније функционисање интернет сајтова
        и страница. Колачићи омогућавају нашем сајту да Вас препозна и запамти
        важне информације како би Вам коришћење сајта било удобније.
      </p>
      <h2>Како трећа лица користе колачиће на нашем сајту?</h2>
      <p>
        Како би обезбедило одређене услуге на свом интернет сајту, предузеће
        Inndex Media д.о.о. Београд може сарађивати са трећим лицима. Други
        оглашивачи и остале организације могу користити своје колачиће да би
        прикупили информације о Вашим активностима на нашем интернет сајту. Ове
        информације могу користити трећем лицу да прикаже огласе за које верује
        да ће Вама бити од значаја на основу садржаја који сте гледали.
        Предметни оглашивачи могу користити ове информације у циљу утврђивања
        степена ефективности својих огласа. Колачиће трећег лица ми не
        контролишемо, да бисте их онемогућили или избрисали молимо Вас да више
        информација и инструкције за онемогућавање потражите на интернет
        страници релевантног трећег лица.
      </p>
      <h2>Које колачиће користимо?</h2>
      <p>
        Предузеће Inndex Media д.о.о. Београд на овом интернет сајту користи
        једино колачиће функционалности. Колачићи функционалности нам помажу да
        унапредимо Ваше корисничко искуство приликом посете нашем сајту.
      </p>
      <h2>Измене ове Политике о колачићима</h2>
      <p>
        Политика о колачићима се ажурира с времена на време. Због тога,
        саветујемо вас да периодично погледате ову страницу због могућих измена.
        Обавестићемо вас о изменама постављањем новог документа на ову страну, и
        захтевом да поновите прихватање нове верзије Политике о колачићима.
        Измене у документу важе од момента постављања на ову страну.
      </p>
      <h2>Контактирајте нас</h2>
      <p>
        Уколико имате било каква питања или сугестије у вези наше Политике о
        колачићима, не оклевајте и контактирајте нас на office@inmedia.rs
      </p>
    </>
  );
}

export default CookiePolicyCyrilic;
