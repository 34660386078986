import React from "react";
import SeoTags from "../components/seoTags";
import { useNavigate } from "react-router-dom";
function NotFound(props) {
  const navigate = useNavigate();
  const { lang, locale } = props;
  return (
    <>
      <SeoTags
        title={"404 - Stranica nije pronađena".translate()}
        description={"Greška 404! Stranica koju tražite nije pronađena.".translate()}
        type="website"
        locale={locale}
        lang={lang}
        noCanonical={true}
      />
      <main className="container-fluid p-0">
        <div className="container-md">
          <div className="row">
            <div className="col-lg">
              <div className="m-5 text-center">
                <h1>404</h1>
                <div className="mb-3">
                  {"Greška 404! Stranica koju tražite nije pronađena.".translate()}
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    navigate(global.getRoot() + "/");
                  }}
                >
                  {"Idi na naslovnu stranu".translate()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default NotFound;
