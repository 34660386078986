import React from "react";
import { useState, useEffect } from "react";
import PromoApp from "../components/promoApp";
import Header from "../components/header";
import Group from "../components/home/group";
import MostPopular from "../components/locations/mostPopular";
import MostLikesComments from "../components/locations/mostLikesComments";
import SidebarLatest from "../components/locations/sidebarLatest";
import SeoTags from "../components/seoTags";

function Locations(props) {
  const { lang, locale } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupList, setGroupList] = useState("");
  const [seo, setSeo] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  global.setLang(lang);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    const requestOptionsSearchSeo = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        page: "locations",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_seo.php", requestOptionsSearchSeo)
      .then((response) => response.json())
      .then((responseData) => {
        setSeo(responseData);
      });
    const requestOptionsSearch = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_groups.php", requestOptionsSearch)
      .then((response) => response.json())
      .then((objects) => {
        let objList = "";
        let totalObjects = objects.length;
        objects.map((group, i) => {
          objList += group.name_sr;
          i + 1 < totalObjects ? (objList += ", ") : (objList += ".");
        });
        setGroupList(objList);
        // let newData = [];
        // objects.map((group, i) => {
        //   group.subgroup_visible = true;
        //   newData[i] = group;
        // });
        // setData(newData);
        setData(objects);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleToggleSubmenu(index) {
    let newData = [];
    data.map((group, i) => {
      let currentState = group.subgroup_visible;
      i === index
        ? (group.subgroup_visible = !currentState)
        : (group.subgroup_visible = false);
      newData[i] = group;
    });
    setData(newData);
  }
  return (
    <>
      <SeoTags
        title={!loading && seo["seo_title_" + lang]}
        description={!loading && seo["seo_description_" + lang]}
        type="website"
        locale={locale}
        lang={lang}
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              h1
              backTo={global.getRoot() + "/"}
              firstLine={global.data()["name_" + lang]}
              secondLine={"Lokacije".translate()}
              loading={loading}
              icon={<i className="bi bi-geo-alt me-2 icon-primary"></i>}
            />
          </div>
        </div>
        <div className="container-md">
          <div className="row">
            <div className="col-lg-8">
              {width > 767 ? (
                <div className="row px-2 pt-1 pb-3">
                  <div className="col-md-6">
                    <div className="row">
                      {loading && (
                        <>
                          <Group loading={loading} />
                          <Group loading={loading} />
                          <Group loading={loading} />
                          <Group loading={loading} />
                        </>
                      )}
                      {!loading &&
                        data.map((group, index) => {
                          if (!(index & 1)) {
                            return (
                              <Group
                                key={group.group_id}
                                onToggleSubmenu={handleToggleSubmenu}
                                index={index}
                                group={group}
                                lang={lang}
                                loading={loading}
                                h3
                              />
                            );
                          }
                        })}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      {loading && (
                        <>
                          <Group loading={loading} />
                          <Group loading={loading} />
                          <Group loading={loading} />
                          <Group loading={loading} />
                        </>
                      )}
                      {!loading &&
                        data.map((group, index) => {
                          if (index & 1) {
                            return (
                              <Group
                                key={group.group_id}
                                onToggleSubmenu={handleToggleSubmenu}
                                index={index}
                                group={group}
                                lang={lang}
                                loading={loading}
                                h3
                              />
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row px-2 pt-1 pb-3">
                  {loading && (
                    <>
                      <Group loading={loading} />
                      <Group loading={loading} />
                      <Group loading={loading} />
                      <Group loading={loading} />
                      <Group loading={loading} />
                      <Group loading={loading} />
                      <Group loading={loading} />
                      <Group loading={loading} />
                    </>
                  )}
                  {!loading &&
                    data.map((group, index) => (
                      <Group
                        h2
                        key={group.group_id}
                        onToggleSubmenu={handleToggleSubmenu}
                        index={index}
                        group={group}
                        lang={lang}
                        loading={loading}
                      />
                    ))}
                </div>
              )}
              <div className="row g-2 px-2 pt-1 pb-3">
                <MostPopular lang={lang} showSubheader={true} category="0" />
                <MostLikesComments lang={lang} showSubheader={true} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sticky-column mt-3 pb-3">
                <SidebarLatest lang={lang} showSubheader={true} />
                <PromoApp sidebar={true} lang={lang} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Locations;
