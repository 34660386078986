import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
function Name(props) {
  const { name, loading } = props;
  return (
    <h2 className="object-list-item-name">
      {loading && (
        <Placeholder animation="glow">
          <Placeholder xs={6} size="lg" />
        </Placeholder>
      )}
      {!loading && name && name}
    </h2>
  );
}

export default Name;
