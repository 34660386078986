import React, { useState, useEffect } from "react";
import SeoTags from "../components/seoTags";
import CookiePolicyEnglish from "../components/cookiePolicyEnglish";
import CookiePolicySerbian from "../components/cookiePolicySerbian";
import CookiePolicyCyrilic from "../components/cookiePolicyCyrilic";
import Header from "../components/header";
function Cookies(props) {
  const { lang, locale } = props;
  const [seo, setSeo] = useState([]);
  const [loading, setLoading] = useState(true);

  global.setLang(lang);

  useEffect(() => {
    setLoading(true);
    const requestOptionsSearchSeo = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        page: "cookies",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_seo.php", requestOptionsSearchSeo)
      .then((response) => response.json())
      .then((responseData) => {
        setSeo(responseData);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <SeoTags
        title={!loading && seo["seo_title_" + lang]}
        description={!loading && seo["seo_description_" + lang]}
        type="article"
        locale={locale}
        lang={lang}
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo={global.getRoot() + "/"}
              firstLine={global.data()["app_name_" + lang]}
              secondLine={"Politika o kolačićima".translate()}
              icon={
                <i className="bi bi-file-earmark-text me-2 icon-primary"></i>
              }
            />
          </div>
        </div>
        <div className="container-md my-5">
          <div className="row">
            <div className="m-auto col-lg-8 policy-page">
              {lang === "sr" && <CookiePolicySerbian />}
              {lang === "cp" && <CookiePolicyCyrilic />}
              {lang === "en" && <CookiePolicyEnglish />}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Cookies;
