import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SubHeader from "../../components/subHeader";
import Placeholder from "react-bootstrap/Placeholder";

function SidebarCategories(props) {
  const { lang, category, showSubheader } = props;
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const requestOptionsGroup = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
      }),
    };
    fetch(
      process.env.REACT_APP_API + "get_event_categories.php",
      requestOptionsGroup
    )
      .then((response) => response.json())
      .then((categories) => {
        setData(categories);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {showSubheader && (
        <SubHeader
          loading={loading}
          title={"Kategorije".translate()}
          h2
          icon={<i className="bi bi-calendar-date icon-primary"></i>}
        />
      )}
      <div className="row g-0 px-2 pt-1 pb-3 mb-3">
        <ul className="events-sidebar-group-list">
          {loading && (
            <>
              <li className="events-sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="events-sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="events-sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
            </>
          )}
          {!loading && (
            <li
              className={
                !category
                  ? "events-sidebar-group-item-active"
                  : "events-sidebar-group-item"
              }
            >
              <Link
                to={global.getRoot() + "/najave/"}
                className="events-sidebar-group-item-inner"
              >
                <div
                  className={
                    !category
                      ? "events-sidebar-group-title-active"
                      : "events-sidebar-group-title"
                  }
                >
                  {"Sve najave".translate()}
                </div>
              </Link>
            </li>
          )}
          {!loading &&
            data.map((categoryItem) => (
              <li
                key={categoryItem.category_id}
                className={
                  category === categoryItem.stub
                    ? "events-sidebar-group-item-active"
                    : "events-sidebar-group-item"
                }
              >
                <Link
                  to={global.getRoot() + "/najave/" + categoryItem.stub}
                  className="events-sidebar-group-item-inner"
                >
                  <div
                    className={
                      category === categoryItem.stub
                        ? "events-sidebar-group-title-active"
                        : "events-sidebar-group-title"
                    }
                  >
                    {categoryItem["name_" + lang]}
                  </div>
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

export default SidebarCategories;
