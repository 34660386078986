import React from "react";
import { Helmet } from "react-helmet-async";
function SeoTags(props) {
  const { title, description, type, image, locale, lang, noCanonical } = props;

  let path = window.location.href.split(window.location.host)[1];
  let fullPath = window.location.href;
  let canonical = "";
  let displayImage = "";
  // if (lang === "cp") {
  //   canonical = global.data().url + path.substring(4);
  // } else {
  canonical = global.data().url + path.substring(1);
  //}

  image
    ? (displayImage = image)
    : (displayImage =
        process.env.REACT_APP_CDN +
        global.data().alias +
        "/static/inndex-logo.png");

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      {!noCanonical ? <link rel="canonical" href={canonical} /> : null}
      <meta property="og:type" content={type} />
      <meta property="og:locale" content={locale} />
      <meta property="og:url" content={fullPath} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={displayImage} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={fullPath} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={displayImage} />
    </Helmet>
  );
}

export default SeoTags;
