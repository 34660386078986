import React from "react";
import { Link } from "react-router-dom";
import Placeholder from "react-bootstrap/Placeholder";

function SearchResultsEvent(props) {
  const { data, lang, locale, loading } = props;

  function formatDate(date) {
    let options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  function formatDay(date) {
    let dateTime = new Date(parseInt(date));
    return dateTime.getDate();
  }

  function formatMonth(date) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Maj",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ];
    let dateTime = new Date(parseInt(date));
    return monthNames[dateTime.getMonth()].translate();
  }

  function formatTime(date) {
    let options = { hour: "numeric", minute: "2-digit", second: undefined };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleTimeString(locale, options);
  }

  function dateIsBigger(date) {
    let biggerDate = Date.now() > parseInt(date);
    return biggerDate;
  }

  function status(dateStart, dateEnd) {
    let dateNow = Date.now();
    return parseInt(dateStart) < dateNow && dateNow < parseInt(dateEnd) ? (
      <div className="s-event-list-status ongoing">
        {"U toku...".translate()}
      </div>
    ) : dateNow > parseInt(dateEnd) ? (
      <div className="s-event-list-status expired">
        {"Završeno".translate()}
      </div>
    ) : (
      ""
    );
  }

  return (
    <>
      {!loading && data && (
        <Link
          to={
            global.getRoot() + "/najave/" + data.category_stub + "/" + data.stub
          }
          className="col-xl-6 g-3 decoration"
        >
          <div
            className={
              dateIsBigger(data.finishing_date + "000")
                ? "event-list-wrapper-expired"
                : "event-list-wrapper"
            }
          >
            <div className="s-event-list-content">
              <div
                className={
                  dateIsBigger(data.finishing_date + "000")
                    ? "s-event-list-big-date-expired"
                    : "s-event-list-big-date"
                }
              >
                <div className="s-event-list-big-day">
                  {formatDay(data.starting_date + "000")}
                </div>
                <div className="s-event-list-big-month">
                  {formatMonth(data.starting_date + "000")}
                </div>
              </div>
              <div className="event-list-event-data">
                <div className="s-event-list-meta">
                  <div className="event-list-category">
                    {data["category_name_" + lang]}
                  </div>
                  <div className="ms-2 me-2 event-list-separator">|</div>
                  <div
                    className={
                      dateIsBigger(data.finishing_date + "000")
                        ? "event-list-date-expired"
                        : "event-list-date"
                    }
                  >
                    <i className="bi bi-calendar3 me-1 icon-primary"></i>
                    {data.starting_date + 86400 <= data.finishing_date
                      ? formatDate(data.starting_date + "000") +
                        " - " +
                        formatDate(data.finishing_date + "000")
                      : formatDate(data.starting_date + "000")}
                  </div>

                  {data.starting_date + 86400 > data.finishing_date && (
                    <div
                      className={
                        dateIsBigger(data.finishing_date + "000")
                          ? "event-list-date-expired ms-2"
                          : "event-list-date ms-2"
                      }
                    >
                      <i className="bi bi-clock me-1 icon-primary"></i>
                      {formatTime(data.starting_date + "000")}
                    </div>
                  )}
                </div>
                <div className="s-event-list-title">
                  {data["title_" + lang]}
                </div>
              </div>
            </div>
            {status(data.starting_date + "000", data.finishing_date + "000")}
          </div>
        </Link>
      )}
    </>
  );
}

export default SearchResultsEvent;
