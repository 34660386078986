import React, { useState, useEffect } from "react";
import SubHeader from "./subHeader";
import NewsItem from "./newsItem";

function SidebarLatestNews(props) {
  const { newsSingle, lang, locale, showSubheader } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        skip: newsSingle,
      }),
    };
    fetch(process.env.REACT_APP_API + "get_latest_news.php", requestOptions)
      .then((response) => response.json())
      .then((news) => {
        setData(news);
        setLoading(false);
      });
  }, [newsSingle]);

  return (
    <>
      {showSubheader && (
        <SubHeader
          title={"Najnovije vesti".translate()}
          linkTo={global.getRoot() + "/vesti"}
          loading={loading}
          h2
          icon={<i className="bi bi-newspaper icon-primary"></i>}
        />
      )}
      <div className="row g-0 px-2 pt-1 pb-3 mb-3">
        {loading && (
          <>
            <NewsItem loading={loading} locale={locale} lang={lang} />
            <NewsItem loading={loading} locale={locale} lang={lang} />
            <NewsItem loading={loading} locale={locale} lang={lang} />
            <NewsItem loading={loading} locale={locale} lang={lang} />
            <NewsItem loading={loading} locale={locale} lang={lang} />
          </>
        )}
        {!loading &&
          data.map((newsItem) => (
            <NewsItem
              key={newsItem.news_id}
              loading={loading}
              newsItem={newsItem}
              locale={locale}
              h3
              lang={lang}
            />
          ))}
      </div>
    </>
  );
}

export default SidebarLatestNews;
