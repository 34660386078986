import React from "react";
import Image from "../image";
import Placeholder from "react-bootstrap/Placeholder";

function MainPhoto(props) {
  const { data, lang, loading } = props;
  return (
    <div className="row-md mb-3">
      {loading && (
        <div className="col-md">
          <div className="news-main-photo">
            <Placeholder animation="glow">
              <Placeholder style={{ width: "100%", height: "100%" }} />
            </Placeholder>
          </div>
        </div>
      )}
      {!loading && data && (
        <div className="col-md">
          <div className="news-main-photo">
            <Image
              imageSrc={data.path + "large/" + data.filename}
              imageAlt={data["photo_text_" + lang]}
            />
            {data.credit !== "" && (
              <div className="photo-source">
                {"Foto:".translate() + " " + data.credit}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MainPhoto;
