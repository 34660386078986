import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Image from "../image";
import SubHeader from "../../components/subHeader";
import Collapse from "react-bootstrap/Collapse";
import Placeholder from "react-bootstrap/Placeholder";

function SidebarCategories(props) {
  const { lang, group, subgroup } = props;
  const [groupData, setGroupData] = useState([]);
  const [loadingGroupData, setLoadingGroupData] = useState(true);

  function handleToggle(index) {
    let newData = [];
    groupData.map((groupItem, i) => {
      let currentState = groupItem.subgroup_visible;
      i === index
        ? (groupItem.subgroup_visible = !currentState)
        : (groupItem.subgroup_visible = false);
      newData[i] = groupItem;
    });
    setGroupData(newData);
  }

  useEffect(() => {
    const requestOptionsGroup = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_groups.php", requestOptionsGroup)
      .then((response) => response.json())
      .then((objects) => {
        let newData = [];
        let stubGroup = "lokacije/" + group;
        let stubSubgroup = subgroup;
        objects.map((groupItem, i) => {
          stubGroup === groupItem.stub
            ? (groupItem.subgroup_visible = true)
            : (groupItem.subgroup_visible = false);
          if (stubGroup === groupItem.stub) {
            groupItem.subgroups.map((subgroupItem) => {
              stubSubgroup === subgroupItem.stub
                ? (subgroupItem.item_active = true)
                : (subgroupItem.item_active = false);
            });
          }
          newData[i] = groupItem;
        });
        setGroupData(newData);
        setLoadingGroupData(false);
      });
  }, [subgroup]);

  return (
    <>
      <SubHeader
        loading={loadingGroupData}
        title={"Kategorije".translate()}
        h2
        icon={<i className="bi bi-geo-alt icon-primary"></i>}
      />
      <div className="row g-0 px-2 pt-1 pb-3">
        <ul className="sidebar-group-list">
          {loadingGroupData && (
            <>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
              <li className="sidebar-group-item">
                <Placeholder animation="glow">
                  <Placeholder xs={12} size="sm" />
                </Placeholder>
              </li>
            </>
          )}
          {!loadingGroupData &&
            groupData.map((groupList, index) => (
              <li
                key={groupList.group_id}
                className={
                  groupList.subgroup_visible
                    ? "sidebar-group-item-active"
                    : "sidebar-group-item"
                }
              >
                <div
                  onClick={() => handleToggle(index)}
                  className="sidebar-group-item-inner"
                >
                  <h3 className="sidebar-group-title">
                    <Image
                      imageUi={true}
                      imageSrc={groupList.filename}
                      imageWidth={25}
                    />
                    {groupList["name_" + lang]}
                  </h3>
                  <i
                    className={
                      groupList.subgroup_visible
                        ? "bi bi-caret-down-fill green"
                        : "bi bi-caret-right-fill green"
                    }
                  ></i>
                </div>
                <Collapse in={groupList.subgroup_visible}>
                  <div>
                    <ul className="sidebar-subgroup-list-visible">
                      {groupList.subgroups.map((subgroupList) => (
                        <Link
                          key={subgroupList.subgroup_id}
                          to={
                            global.getRoot() +
                            "/" +
                            groupList.stub +
                            "/" +
                            subgroupList.stub
                          }
                        >
                          <li
                            className={
                              subgroupList.item_active
                                ? "sidebar-subgroup-item-active"
                                : "sidebar-subgroup-item"
                            }
                          >
                            <i className="bi bi-caret-right-fill"></i>
                            {subgroupList["name_" + lang] +
                              " (" +
                              subgroupList.count +
                              ")"}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </Collapse>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
}

export default SidebarCategories;
