import React from "react";
import Placeholder from "react-bootstrap/Placeholder";

function NewsContent(props) {
  const { data, lang, locale, loading } = props;

  function formatDatetime(date) {
    let options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  return (
    <>
      <div className="row-md mb-3">
        {loading && (
          <Placeholder animation="glow">
            <Placeholder xs={10} size="lg" />
          </Placeholder>
        )}
        {!loading && data && (
          <h1 className="single-news-title">{data["title_" + lang]}</h1>
        )}
        <div className="single-events-date-time">
          <div className="single-events-date-icon">
            <i className="bi bi-calendar3"></i>
          </div>
          <div className="single-events-date-value">
            {loading && (
              <Placeholder animation="glow">
                <Placeholder xs={3} size="sm" />
              </Placeholder>
            )}
            {!loading && data && formatDatetime(data.published + "000")}
          </div>
          {!loading &&
            data &&
            data.published_by !== "" &&
            data.show_publisher === "1" &&
            data.location.location_id !== "" && (
              <>
                <div className="single-events-date-icon">
                  <i className="bi bi-geo-alt"></i>
                </div>
                <div className="single-events-date-value">
                  {loading && (
                    <Placeholder animation="glow">
                      <Placeholder xs={3} size="sm" />
                    </Placeholder>
                  )}
                  {!loading && data && (
                    <a
                      href={
                        global.getRoot() +
                        "/" +
                        data.location.stub_1 +
                        "/" +
                        data.location.stub_2 +
                        "/" +
                        data.location.stub_3
                      }
                    >
                      {data.location["name_" + lang]}
                    </a>
                  )}
                </div>
              </>
            )}
        </div>
      </div>
      <div className="row-md mb-3">
        {loading && (
          <Placeholder animation="glow">
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={12} size="sm" />
            <Placeholder xs={6} size="sm" />
          </Placeholder>
        )}
        {!loading && data && (
          <>
            <div
              className="single-news-text"
              dangerouslySetInnerHTML={{ __html: data["text_" + lang] }}
            ></div>
            {data.source !== "" && (
              <div className="pt-4">
                <em>
                  <strong>{"Izvor:".translate() + " "}</strong>
                  {data.source}
                </em>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default NewsContent;
