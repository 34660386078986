import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";

function NewsContent(props) {
  const { data, lang, locale, loading } = props;

  function formatDate(date) {
    let options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  function formatTime(date) {
    let options = { hour: "2-digit", minute: "2-digit", second: undefined };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleTimeString(locale, options);
  }

  function dateIsBigger(date) {
    let biggerDate = Date.now() > parseInt(date);
    return biggerDate;
  }

  return (
    <>
      <div className="row-md mb-3">
        {loading && (
          <>
            <Placeholder animation="glow">
              <Placeholder xs={10} size="lg" />
            </Placeholder>
            <Placeholder animation="glow">
              <Placeholder xs={6} size="sm" />
            </Placeholder>
          </>
        )}
        {!loading && data && (
          <>
            <h1 className="single-news-title">{data["title_" + lang]}</h1>
            <div className="single-events-date-time">
              <div className="single-events-date-icon">
                <i className="bi bi-calendar3"></i>
              </div>
              <div
                className={
                  dateIsBigger(data.finishing_date + "000")
                    ? "single-events-date-value crossline"
                    : "single-events-date-value"
                }
              >
                {parseInt(data.starting_date) + 86400 <=
                parseInt(data.finishing_date)
                  ? formatDate(data.starting_date + "000") +
                    " - " +
                    formatDate(data.finishing_date + "000")
                  : formatDate(data.starting_date + "000")}
              </div>
              {parseInt(data.starting_date) + 86400 >
                parseInt(data.finishing_date) && (
                <>
                  <div className="single-events-date-icon">
                    <i className="bi bi-clock"></i>
                  </div>
                  <div
                    className={
                      dateIsBigger(data.finishing_date + "000")
                        ? "single-events-date-value crossline"
                        : "single-events-date-value"
                    }
                  >
                    {formatTime(data.starting_date + "000")}
                  </div>
                </>
              )}
              {!loading &&
                data &&
                data.published_by !== "" &&
                data.show_publisher === "1" &&
                data.location.location_id !== "" && (
                  <>
                    <div className="single-events-date-icon">
                      <i className="bi bi-geo-alt"></i>
                    </div>
                    <div className="single-events-date-value">
                      {loading && (
                        <Placeholder animation="glow">
                          <Placeholder xs={3} size="sm" />
                        </Placeholder>
                      )}
                      {!loading && data && (
                        <a
                          href={
                            global.getRoot() +
                            "/" +
                            data.location.stub_1 +
                            "/" +
                            data.location.stub_2 +
                            "/" +
                            data.location.stub_3
                          }
                        >
                          {data.location["name_" + lang]}
                        </a>
                      )}
                    </div>
                  </>
                )}
              {dateIsBigger(data.finishing_date + "000") && (
                <div className="event-expired">{"Završeno".translate()}</div>
              )}
            </div>
          </>
        )}
      </div>
      <div className="row-md mb-3">
        {loading && (
          <>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} size="sm" />
              <Placeholder xs={12} size="sm" />
              <Placeholder xs={8} size="sm" />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} size="sm" />
              <Placeholder xs={12} size="sm" />
              <Placeholder xs={12} size="sm" />
              <Placeholder xs={4} size="sm" />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} size="sm" />
              <Placeholder xs={12} size="sm" />
              <Placeholder xs={6} size="sm" />
            </Placeholder>
          </>
        )}
        {!loading && data && (
          <>
            <div
              className="single-news-text"
              dangerouslySetInnerHTML={{ __html: data["text_" + lang] }}
            ></div>
            {data.source !== "" && (
              <div className="pt-4">
                <em>
                  <strong>{"Izvor:".translate() + " "}</strong>
                  {data.source}
                </em>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default NewsContent;
