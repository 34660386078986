import React from "react";

function PromoApp(props) {
  const { sidebar, lang } = props;
  let appPhrase = "";
  let appTitle = "";

  if (global.data().ios_app === "" && global.data().android_app !== "") {
    appPhrase = "Android";
    appTitle = "Android";
  }
  if (global.data().ios_app !== "" && global.data().android_app === "") {
    appPhrase = "iPhone";
    appTitle = "iPhone";
  }
  if (global.data().ios_app !== "" && global.data().android_app !== "") {
    appPhrase = "iPhone ili Android".translate();
    appTitle = "iPhone / Android";
  }

  return (
    <div
      className={
        sidebar
          ? "container-fluid search-promo-bg-sidebar p-3"
          : "container-fluid search-promo-bg p-3"
      }
    >
      <div className="container-md">
        <div
          className={
            sidebar ? "row search-promo-wrap-sidebar" : "row search-promo-wrap"
          }
        >
          <div className="col-5 search-promo-left">
            <img
              src={
                process.env.REACT_APP_CDN +
                global.data().alias +
                "/static/" +
                "phones.png"
              }
              // height="50"
              alt={appTitle + " " + "aplikacija".translate()}
            />
          </div>
          <div
            className={
              sidebar
                ? "col search-promo-right-sidebar"
                : "col search-promo-right"
            }
          >
            <h2>{appTitle + " " + "aplikacija".translate()}</h2>
            <p>
              {"Preuzmite".translate() +
                " " +
                global.data()["app_name_" + lang] +
                " " +
                "aplikaciju za vaš".translate() +
                " " +
                appPhrase +
                " " +
                "mobilni uređaj.".translate()}
            </p>
            <div
              className={
                sidebar ? "search-promo-apps-sidebar" : "search-promo-apps"
              }
            >
              {global.data().ios_app === "pending" && (
                <div>
                  <img
                    src={
                      process.env.REACT_APP_CDN +
                      global.data().alias +
                      "/static/" +
                      "app-store.svg"
                    }
                    height="50"
                    alt={"Uskoro".translate()}
                    title={"Uskoro".translate()}
                  />
                </div>
              )}
              {global.data().ios_app !== "" &&
                global.data().ios_app !== "pending" && (
                  <a
                    href={global.data().ios_app}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={
                        process.env.REACT_APP_CDN +
                        global.data().alias +
                        "/static/" +
                        "app-store.svg"
                      }
                      height="50"
                      alt={"Preuzmi aplikaciju na App Store".translate()}
                      title={"Preuzmi aplikaciju na App Store".translate()}
                    />
                  </a>
                )}
              {global.data().android_app === "pending" && (
                <div>
                  <img
                    src={
                      process.env.REACT_APP_CDN +
                      global.data().alias +
                      "/static/" +
                      "play-store.svg"
                    }
                    height="50"
                    alt={"Uskoro".translate()}
                    title={"Uskoro".translate()}
                  />
                </div>
              )}
              {global.data().android_app !== "" &&
                global.data().android_app !== "pending" && (
                  <a
                    href={global.data().android_app}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={
                        process.env.REACT_APP_CDN +
                        global.data().alias +
                        "/static/" +
                        "play-store.svg"
                      }
                      height="50"
                      alt={"Preuzmi aplikaciju na Google Play".translate()}
                      title={"Preuzmi aplikaciju na Google Play".translate()}
                    />
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoApp;
