import React from "react";
import SeoTags from "../components/seoTags";
import { useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Header from "../components/header";
import SearchResultsSegment from "../components/searchResults/searchResultsSegment";

function SearchResults(props) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { lang, locale } = props;
  const searchTerm = searchParams.get("search_term");
  const searchGroup = searchParams.get("search_group");
  const [seo, setSeo] = useState([]);

  global.setLang(lang);

  useEffect(() => {
    setLoading(true);
    const requestOptionsSearchSeo = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        page: "searchResults",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_seo.php", requestOptionsSearchSeo)
      .then((response) => response.json())
      .then((responseData) => {
        setSeo(responseData);
      });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        searchTerm: searchTerm,
        searchGroup: searchGroup,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_search_results.php", requestOptions)
      .then((response) => response.json())
      .then((results) => {
        setData(results);
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [searchTerm, searchGroup]);

  return (
    <>
      <SeoTags
        title={!loading && seo["seo_title_" + lang]}
        description={
          !loading &&
          seo["seo_description_" + lang].replace(
            /%termin%|%термин%/g,
            searchTerm
          )
        }
        type="website"
        locale={locale}
        lang={lang}
      />
      <main className="container-fluid p-0">
        <div className="container-fluid p-0 header-wrap">
          <div className="container-md">
            <Header
              backTo={global.getRoot() + "/"}
              firstLine={global.data()["name_" + lang]}
              secondLine={"Rezultati pretrage".translate()}
              loading={loading}
              icon={<i className="bi bi-search me-2 icon-primary"></i>}
            />
          </div>
        </div>
        <div className="container-md">
          <div className="row">
            <div className="col-lg pt-3">
              {searchGroup === "0" || searchGroup === "1" ? (
                <SearchResultsSegment
                  loading={loading}
                  data={!loading && data.locations}
                  title={"Lokacije".translate()}
                  icon={<i className="bi bi-geo-alt icon-primary"></i>}
                  type="locations"
                  lang={lang}
                  locale={locale}
                />
              ) : null}
              {searchGroup === "0" || searchGroup === "2" ? (
                <SearchResultsSegment
                  loading={loading}
                  data={!loading && data.events}
                  title={"Najave".translate()}
                  icon={<i className="bi bi-calendar-date icon-primary"></i>}
                  type="events"
                  lang={lang}
                  locale={locale}
                />
              ) : null}
              {searchGroup === "0" || searchGroup === "3" ? (
                <SearchResultsSegment
                  loading={loading}
                  data={!loading && data.news}
                  title={"Vesti".translate()}
                  icon={<i className="bi bi-newspaper icon-primary"></i>}
                  type="news"
                  lang={lang}
                  locale={locale}
                />
              ) : null}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default SearchResults;
