import React from "react";
import { Link } from "react-router-dom";
function SiteFooter(props) {
  const { lang } = props;
  return (
    <div className="container-fluid bg-dark">
      <footer className="container-md py-3 my-0">
        <div className="row">
          <div className="col-12 justify-content-center d-flex mb-2">
            <img
              src={
                process.env.REACT_APP_CDN +
                global.data().alias +
                "/static/site-logo.png"
              }
              className="d-inline-block align-top"
              alt={global.data()["app_name_" + lang] + " logo"}
              height="60px"
            />
          </div>
          <div className="col-12">
            <ul className="nav justify-content-center mb-2 footer-links">
              <li className="nav-item">
                <Link
                  to={global.getRoot() + "/kontakt"}
                  className="nav-link px-2"
                >
                  {"Kontakt".translate()}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={global.getRoot() + "/politika-o-kolacicima"}
                  className="nav-link px-2"
                >
                  {"Politika o kolačićima".translate()}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={global.getRoot() + "/politika-privatnosti"}
                  className="nav-link px-2"
                >
                  {"Politika privatnosti".translate()}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row footer-down">
          <div className="col-6 footer-copy">
            {process.env.REACT_APP_COPYRIGHT +
              " " +
              global.data()["publisher_" + lang]}
          </div>
          <div className="col-6 footer-icons">
            {global.data().ios_app !== "" &&
              global.data().ios_app !== "pending" && (
                <div>
                  <a
                    href={global.data().ios_app}
                    target="_blank"
                    rel="noreferrer"
                    title={"Preuzmi aplikaciju na App Store".translate()}
                  >
                    <i className="bi bi-apple"></i>
                  </a>
                </div>
              )}
            {global.data().android_app !== "" &&
              global.data().android_app !== "pending" && (
                <div>
                  <a
                    href={global.data().android_app}
                    target="_blank"
                    rel="noreferrer"
                    title={"Preuzmi aplikaciju na Google Play".translate()}
                  >
                    <i className="bi bi-google-play"></i>
                  </a>
                </div>
              )}
            {global.data().facebook_page !== "" && (
              <div>
                <a
                  href={global.data().facebook_page}
                  target="_blank"
                  rel="noreferrer"
                  title={"Poseti našu Facebook stranicu".translate()}
                >
                  <i className="bi bi-facebook"></i>
                </a>
              </div>
            )}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default SiteFooter;
