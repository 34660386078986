import React, { useState, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes, useNavigate, Link } from "react-router-dom";
import NavBar from "./components/navbar";
import "./App.css";
import Objects from "./pages/objects";
import Object from "./pages/object";
import ObjectMap from "./pages/objectMap";
import Home from "./pages/home";
import SearchResults from "./pages/searchResults";
import Events from "./pages/events";
import EventsSingle from "./pages/eventsSingle";
import News from "./pages/news";
import NewsSingle from "./pages/newsSingle";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import SiteFooter from "./components/siteFooter";
import PrivacyPolicy from "./pages/privacyPolicy";
import ScrollTop from "./helpers/scrollToTop";
import Locations from "./pages/locations";
import CookieConsent from "react-cookie-consent";
import Cookies from "./pages/cookies";
import NotFound from "./pages/notFound";
import Contact from "./pages/contact";

const translation = require("./helpers/translation.json");

const language = () => {
  let lang = localStorage.getItem("language");
  return lang ? lang : process.env.REACT_APP_DEFAULT_LANGUAGE;
};

const locale = () => {
  let locale = localStorage.getItem("locale");
  return locale ? locale : process.env.REACT_APP_DEFAULT_LOCALE;
};

String.prototype.translate = function () {
  return translation[language()][this] === undefined
    ? this
    : translation[language()][this];
};

function App() {
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([
    {
      shortName: "sr",
      name: "Srpski",
      locale: "sr-RS",
    },
    {
      shortName: "en",
      name: "English",
      locale: "en-US",
    },
  ]);
  const [city, setCity] = useState([]);

  global.data = function () {
    return city;
  };

  global.markAsSeen = function (id, published) {
    if ((parseInt(published) + 86400) * 1000 > Date.now()) {
      let exists = false;
      seen.map((item) => {
        if (item.id === id) {
          exists = true;
        }
      });
      if (!exists) {
        let newSeen = [...seen, { id: id, published: published }];
        setSeen(newSeen);
        localStorage.setItem("seen", JSON.stringify(newSeen));
      }
    }
  };

  global.seen = function (id) {
    let response = false;
    seen.map((item) => {
      if (item.id === id) {
        response = true;
      }
    });
    return response;
  };

  const deleteToast = (index) => {
    let newToasts = [...toasts];
    newToasts[index].show = false;
    setToasts(newToasts);
    localStorage.setItem("toasts", JSON.stringify(newToasts));
  };

  const addToast = (title, text, type) => {
    if (!type) type = "info";
    let newToasts = [
      ...toasts,
      { title: title, text: text, type: type, show: true },
    ];
    setToasts(newToasts);
    localStorage.setItem("toasts", JSON.stringify(newToasts));
  };

  const setLangCode = () => {
    let langCode = "";
    if (language() === "sr") {
      langCode = "sr-Latn-CS";
    }
    if (language() === "cp") {
      langCode = "sr-Cyrl-CS";
    }
    if (language() === "en") {
      langCode = "en-US";
    }
    document.documentElement.lang = langCode;
  };

  setLangCode();

  const navigate = useNavigate();

  const handleChangeLang = (selectedLang) => {
    if (selectedLang !== language()) {
      localStorage.setItem("language", selectedLang);
      languages.map((language) => {
        if (language.shortName === selectedLang) {
          localStorage.setItem("locale", language.locale);
        }
      });
      //navigate(0);
      let currentUrl = window.location.href.split(window.location.host)[1];
      let newUrl = "";
      //console.log(currentUrl);
      if (currentUrl.includes("/en")) {
        newUrl = currentUrl.replace("/en", global.getRoot());
      } else if (currentUrl.includes("/cp")) {
        newUrl = currentUrl.replace("/cp", global.getRoot());
      } else {
        newUrl = global.getRoot() + currentUrl;
      }
      //console.log(newUrl);
      navigate(newUrl);
      setLangCode();
    }
  };

  global.setLang = function (selectedLang) {
    handleChangeLang(selectedLang);
  };

  global.getRoot = function () {
    let root = "";
    if (language() !== "sr") {
      root = "/" + language();
    }
    return root;
  };

  const [toasts, setToasts] = useState(null);
  const [seen, setSeen] = useState(null);

  useEffect(() => {
    let loadedSeen = JSON.parse(localStorage.getItem("seen"));
    let newSeen = [];
    loadedSeen &&
      loadedSeen.map((loadedSeenItem) => {
        if ((parseInt(loadedSeenItem.published) + 86400) * 1000 > Date.now()) {
          newSeen = [...newSeen, loadedSeenItem];
        }
      });
    setSeen(newSeen);
    localStorage.setItem("seen", JSON.stringify(newSeen));
    let loadedToasts = JSON.parse(localStorage.getItem("toasts"));
    let newToasts = [];
    loadedToasts &&
      loadedToasts.map((loadedToast) => {
        if (loadedToast.show) {
          newToasts = [...newToasts, loadedToast];
        }
      });
    setToasts(newToasts);
    localStorage.setItem("toasts", JSON.stringify(newToasts));
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_city.php", requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        setCity(responseData);
        if (responseData.use_cp === "1") {
          setLanguages([
            {
              shortName: "sr",
              name: "Srpski (lat)",
              locale: "sr-RS",
            },

            {
              shortName: "cp",
              name: "Српски (ћир)",
              locale: "sr-RS",
            },

            {
              shortName: "en",
              name: "English",
              locale: "en-US",
            },
          ]);
        }
      });
  }, []);

  return (
    <HelmetProvider>
      <CookieConsent
        location="bottom"
        buttonText={"Prihvatam".translate()}
        cookieName="InndexCookieConsentV1"
        style={{ background: "#212529" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      >
        {"Korišćenjem ove stranice prihvatate našu Politiku o kolačićima (eng. Cookie policy). Više detalja pogledajte na strani ".translate()}
        <Link to="/politika-o-kolacicima" className="cookie-link">
          {"Politika o kolačićima".translate()}
        </Link>
      </CookieConsent>
      <NavBar
        lang={language()}
        onChangeLang={handleChangeLang}
        languages={languages}
      />

      <Routes>
        <Route path="/" element={<Home lang="sr" locale="sr-RS" />} />
        <Route path="/najave" element={<Events lang="sr" locale="sr-RS" />} />
        <Route
          path="/najave/:category"
          element={<Events lang="sr" locale="sr-RS" />}
        />
        <Route
          path="/najave/:category/:eventsSingle"
          element={
            <EventsSingle addToast={addToast} lang="sr" locale="sr-RS" />
          }
        />
        <Route path="/vesti" element={<News lang="sr" locale="sr-RS" />} />
        <Route
          path="/vesti/:newsSingle"
          element={<NewsSingle addToast={addToast} lang="sr" locale="sr-RS" />}
        />
        <Route
          path="/lokacije"
          element={<Locations lang="sr" locale="sr-RS" />}
        />
        <Route
          path="/lokacije/:group/:subgroup"
          element={<Objects lang="sr" locale="sr-RS" />}
        />
        <Route
          path="/lokacije/:group/:subgroup/:object"
          element={<Object lang="sr" addToast={addToast} locale="sr-RS" />}
        />
        <Route
          path="/lokacije/:group/:subgroup/:object/map"
          element={<ObjectMap lang="sr" locale="sr-RS" />}
        />
        <Route
          path="/rezultati-pretrage"
          element={<SearchResults lang="sr" locale="sr-RS" />}
        />
        <Route
          path="/politika-privatnosti"
          element={<PrivacyPolicy lang="sr" locale="sr-RS" />}
        />
        <Route
          path="/politika-o-kolacicima"
          element={<Cookies lang="sr" locale="sr-RS" />}
        />
        <Route path="/kontakt" element={<Contact lang="sr" locale="sr-RS" />} />
        <Route path="/en" element={<Home lang="en" locale="en-US" />} />
        <Route
          path="/en/najave"
          element={<Events lang="en" locale="en-US" />}
        />
        <Route
          path="/en/najave/:category"
          element={<Events lang="en" locale="en-US" />}
        />
        <Route
          path="/en/najave/:category/:eventsSingle"
          element={
            <EventsSingle addToast={addToast} lang="en" locale="en-US" />
          }
        />
        <Route path="/en/vesti" element={<News lang="en" locale="en-US" />} />
        <Route
          path="/en/vesti/:newsSingle"
          element={<NewsSingle addToast={addToast} lang="en" locale="en-US" />}
        />
        <Route
          path="/en/lokacije"
          element={<Locations lang="en" locale="en-US" />}
        />
        <Route
          path="/en/lokacije/:group/:subgroup"
          element={<Objects lang="en" locale="en-US" />}
        />
        <Route
          path="/en/lokacije/:group/:subgroup/:object"
          element={<Object lang="en" addToast={addToast} locale="en-US" />}
        />
        <Route
          path="/en/lokacije/:group/:subgroup/:object/map"
          element={<ObjectMap lang="en" locale="en-US" />}
        />
        <Route
          path="/en/rezultati-pretrage"
          element={<SearchResults lang="en" locale="en-US" />}
        />
        <Route
          path="/en/politika-privatnosti"
          element={<PrivacyPolicy lang="en" locale="en-US" />}
        />
        <Route
          path="/en/politika-o-kolacicima"
          element={<Cookies lang="en" locale="en-US" />}
        />
        <Route
          path="/en/kontakt"
          element={<Contact lang="en" locale="en-US" />}
        />

        <Route path="/cp" element={<Home lang="cp" locale="sr-RS" />} />
        <Route
          path="/cp/najave"
          element={<Events lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/najave/:category"
          element={<Events lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/najave/:category/:eventsSingle"
          element={
            <EventsSingle addToast={addToast} lang="cp" locale="sr-RS" />
          }
        />
        <Route path="/cp/vesti" element={<News lang="cp" locale="sr-RS" />} />
        <Route
          path="/cp/vesti/:newsSingle"
          element={<NewsSingle addToast={addToast} lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/lokacije"
          element={<Locations lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/lokacije/:group/:subgroup"
          element={<Objects lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/lokacije/:group/:subgroup/:object"
          element={<Object lang="cp" addToast={addToast} locale="sr-RS" />}
        />
        <Route
          path="/cp/lokacije/:group/:subgroup/:object/map"
          element={<ObjectMap lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/rezultati-pretrage"
          element={<SearchResults lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/politika-privatnosti"
          element={<PrivacyPolicy lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/politika-o-kolacicima"
          element={<Cookies lang="cp" locale="sr-RS" />}
        />
        <Route
          path="/cp/kontakt"
          element={<Contact lang="cp" locale="sr-RS" />}
        />
        <Route path="*" element={<NotFound lang="cp" locale="sr-RS" />} />
      </Routes>
      <ScrollTop />
      <SiteFooter lang={language()} />
      <ToastContainer
        containerPosition="fixed"
        className="p-3"
        position="bottom-start"
      >
        {toasts &&
          toasts.map((toast, i) => (
            <Toast
              key={"toast-" + i}
              show={toast.show}
              animation={true}
              onClose={() => deleteToast(i)}
            >
              {toast.type === "error" && (
                <Toast.Header className="bg-danger bg-opacity-25 text-dark">
                  <strong className="me-auto">
                    <i className="bi bi-exclamation-triangle-fill me-1 text-danger"></i>
                    {toast.title.translate()}
                  </strong>
                </Toast.Header>
              )}
              {toast.type === "success" && (
                <Toast.Header className="bg-success bg-opacity-25 text-dark">
                  <strong className="me-auto">
                    <i className="bi bi-check-circle-fill me-1 text-success"></i>
                    {toast.title.translate()}
                  </strong>
                </Toast.Header>
              )}
              {toast.type === "info" && (
                <Toast.Header className="bg-primary bg-opacity-25 text-dark">
                  <strong className="me-auto">
                    <i className="bi bi-info-circle-fill me-1 text-primary"></i>
                    {toast.title.translate()}
                  </strong>
                </Toast.Header>
              )}
              <Toast.Body>{toast.text.translate()}</Toast.Body>
            </Toast>
          ))}
      </ToastContainer>
    </HelmetProvider>
  );
}

export default App;
