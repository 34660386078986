import React, { useState, useEffect, useCallback, useRef } from "react";
import SeoTags from "../components/seoTags";
import { useParams } from "react-router-dom";
import Header from "../components/header";
import Buttons from "../components/object/buttonsMap";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import SidebarCategories from "../components/object/sidebarCategories";
import PromoApp from "../components/promoApp";
import NotFound from "./notFound";

function ObjectMap(props) {
  const [data, setData] = useState(null);
  const [center, setCenter] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [drivingState, setDrivingState] = useState(null);
  const [walkingState, setWalkingState] = useState(null);
  const [myLocationState, setMyLocationState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { group, subgroup, object } = useParams();
  const { lang, locale } = props;

  global.setLang(lang);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");

  const onLoad = React.useCallback(
    function callback(map) {
      setMap(map);
    },
    [object]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    setDirectionsResponse(null);
    setDistance("");
    setDrivingState(null);
    setWalkingState(null);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        city_id: process.env.REACT_APP_CITY_ID,
        stub: object,
        origin: "web",
      }),
    };
    fetch(process.env.REACT_APP_API + "get_object2.php", requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        setData(actualData);
        setCenter({
          lat: parseFloat(actualData.lat),
          lng: parseFloat(actualData.lng),
        });
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setOrigin({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setDestination({
              lat: parseFloat(actualData.lat),
              lng: parseFloat(actualData.lng),
            });
          },
          function (error) {
            setDestination({
              lat: parseFloat(actualData.lat),
              lng: parseFloat(actualData.lng),
            });
            setDrivingState("disabled");
            setWalkingState("disabled");
            setMyLocationState("disabled");
          }
        );
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [object]);

  async function calculateRoute(mode) {
    let travelMode;
    if (mode === "driving") {
      //eslint-disable-next-line no-undef
      travelMode = google.maps.TravelMode.DRIVING;
      if (drivingState !== "disabled") {
        setDrivingState("active");
      }
      if (walkingState !== "disabled") {
        setWalkingState(null);
      }
    } else {
      //eslint-disable-next-line no-undef
      travelMode = google.maps.TravelMode.WALKING;
      if (drivingState !== "disabled") {
        setDrivingState(null);
      }
      if (walkingState !== "disabled") {
        setWalkingState("active");
      }
    }
    //eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: travelMode,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
  }

  function handleMyLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
      setOrigin({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
      map.panTo({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }

  function handleDestination() {
    map.panTo(destination);
  }

  function clearRoute() {
    // setDirectionsResponse(null);
    setDistance("");
  }

  return (
    <>
      {!loading && data.response_code === "404" ? (
        <NotFound lang={lang} locale={locale} />
      ) : (
        <>
          <SeoTags
            title={
              !loading &&
              "Mapa".translate() + " - " + data.seo["seo_title_" + lang]
            }
            description={
              !loading &&
              data["name_" + lang] +
                " " +
                "na Google mapi. Pogledajte putanju do lokacije.".translate()
            }
            type="website"
            locale={locale}
            lang={lang}
            image={
              process.env.REACT_APP_CDN +
              (!loading && data.path + "large/" + data.filename)
            }
          />
          <main className="container-fluid p-0">
            <div className="container-fluid p-0 header-wrap">
              <div className="container-md">
                <Header
                  backTo={
                    global.getRoot() +
                    "/lokacije/" +
                    group +
                    "/" +
                    subgroup +
                    "/" +
                    object
                  }
                  firstLine={!loading && data["subgroup_name_" + lang]}
                  secondLine={!loading && data["name_" + lang] + " " + distance}
                  loading={loading}
                  h1
                  icon={<i className="bi bi-geo-alt me-2 icon-primary"></i>}
                />
              </div>
            </div>
            <div className="container-md">
              <div className="row">
                <div className="col-lg-8 pb-4">
                  <Buttons
                    onClickRoute={calculateRoute}
                    drivingState={drivingState}
                    walkingState={walkingState}
                    onClickMyLocation={handleMyLocation}
                    myLocationState={myLocationState}
                    onClickDestination={handleDestination}
                    loading={loading}
                  />

                  <div className="row g-0 p-3 map-container">
                    <div className="col-md col-overflow">
                      <div className="row-md mb-3 h-100">
                        {data && isLoaded ? (
                          <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={15}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={{
                              //zoomControl: false,
                              streetViewControl: false,
                              mapTypeControl: false,
                              fullscreenControl: false,
                            }}
                          >
                            <Marker
                              position={center}
                              animation={window.google.maps.Animation.DROP}
                            />
                            {directionsResponse && (
                              <DirectionsRenderer
                                directions={directionsResponse}
                              />
                            )}
                            <></>
                          </GoogleMap>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sticky-column mt-3 pb-3">
                    <SidebarCategories
                      map={true}
                      group={group}
                      subgroup={subgroup}
                      object={object}
                      lang={lang}
                    />
                    <PromoApp sidebar={true} lang={lang} />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default ObjectMap;
