import React from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";
import Image from "../image";
function SearchResultsNews(props) {
  const { loading, newsItem, locale, lang } = props;

  function formatDate(date) {
    let options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let dateTime = new Date(parseInt(date));
    return dateTime.toLocaleDateString(locale, options);
  }

  return (
    <>
      {!loading && (
        <Link
          className="col-xl-6 search-location-wrap"
          to={global.getRoot() + "/vesti/" + newsItem.stub}
        >
          <div className="search-location-image">
            <Image imageSrc={newsItem.path + "small/" + newsItem.filename} />
          </div>
          <div className="search-location-content">
            <div className="sidebar-news-date">
              {formatDate(newsItem.published + "000")}
            </div>
            <div className="search-location-name">
              {newsItem["title_" + lang]}
            </div>
            <div className="search-location-text">
              {newsItem["text_" + lang].split(" ").slice(0, 10).join(" ") +
                "..."}
            </div>
          </div>
        </Link>
      )}
    </>
  );
}

export default SearchResultsNews;
