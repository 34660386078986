import React from "react";
function PrivacyPolicySerbian() {
  return (
    <>
      <h1>Politika privatnosti</h1>
      <p>Verzija: 1, Važi od: 01.01.2023.</p>
      <p>
        Inndex Media d.o.o. Beograd kreirala je Inndex aplikaciju i web sajt u
        formi besplatnog digitalnog proizvoda. Proizvod je besplatno obezbedilo
        preduzeće Inndex Media d.o.o. Beograd i namenjena je za korišćenje u
        viđenom stanju.
      </p>
      <p>
        Ova stranica služi da informiše posetioce o našoj politici vezanoj za
        prikupljanje, korišćenje i otkrivanje privatnih informacija ukoliko neko
        odluči da koristi naš proizvod.
      </p>
      <p>
        Ukoliko odlučite da koristite naš proizvod, samim korišćenjem prihvatate
        prikupljanje i upotrebu informacija u vezi ovog dokumenta. Privatne
        informacije koje prikupljamo se koriste za pružanje i unapređivanje
        našeg proizvoda. Nećemo koristiti niti deliti vaše privatne informacije
        sa bilo kim izuzev kako je opisano u ovom dokumentu.
      </p>
      <h2>Prikupljanje i korišćenje informacija</h2>
      <p>
        U svrhu boljeg korisničkog iskustva tokom korišćenja našeg proizvoda,
        možemo tražiti od vas da nam dostavite pojedine privatne podatke,
        uključujući ali ne ograničavajući se na vaše ime, prezime i vašu mejl
        adresu. Informacije koje tražimo biće zadržane sa naše strane i
        korišćene kako je opisano u ovom dokumentu.
      </p>
      <p>
        Aplikacija i web sajt koriste usluge trećih strana koje mogu prikupljati
        informacije koje vas mogu identifikovati.
      </p>
      <div>
        <p>
          Veze do Politika privatnosti trećih strana čije usluge koriste
          aplikacija i web sajt.
        </p>
        <ul>
          <li>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Google Play usluge
            </a>
          </li>
          <li>
            <a
              href="https://mapsplatform.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              Google Maps platforma
            </a>
          </li>
        </ul>
      </div>
      <h2>Log podaci</h2>
      <p>
        Želimo da vas obavestimo da kada god koristite naš proizvod, u slučaju
        greške tokom korišćenja, prikupljamo podatke i informacije (koristeći
        proizvode trećih strana) sa uređaja koji koristite koji se nazivaju Log
        podaci. Log podaci mogu sadržati informacije kao što su Internet
        Protocol (IP) adresa vašeg uređaja, naziv uređaja, verzija operativnog
        sistema, konfiguraciju prilikom korišćenja našeg proizvoda, vreme i
        datum korišćenja proizvoda, i druge statističke podatke.
      </p>
      <h2>Kolačići (Cookies)</h2>
      <p>
        Kolačići su datoteke sa malom količinom informacija koje se uobičajeno
        koriste kao jedinstveni identifikatori. Oni se šalju vašem pretraživaču
        od strane web sajtova koje posećujete i čuvaju se u internoj memoriji
        vašeg uređaja.
      </p>
      <p>
        Naš proizvod ne koristi kolačiće eksplicitno. Ipak, proizvod može
        koristiti kod ili biblioteke trećih strana koje koriste kolačiće za
        prikupljanje informacija i unapređivanje njihovih usluga. Imate
        mogućnost da prihvatite ili odbijete ove kolačiće, kao i da budete
        upoznati kada je kolačić poslat na vaš uređaj. Ukoliko odlučite da
        odbijete kolačiće, postoji mogućnost da nećete moći da koristite delove
        našeg proizvoda ili kompletan proizvod.
      </p>
      <h2>Pružaoci usluga</h2>
      <p>
        Možemo angažovati treću stranu, odnosno kompaniju ili pojedinca iz
        sledećih razloga:
      </p>
      <ul>
        <li>Da olakšaju korišćenje našeg proizvoda;</li>
        <li>Da obezbede uslugu u naše ime;</li>
        <li>Da pruže usluge u vezi proizvoda; ili</li>
        <li>Da nam pomognu u analizi načina korišćenja našeg proizvoda.</li>
      </ul>
      <p>
        Želimo da obavestimo korisnike našeg proizvoda da treća strana
        angažovana sa naše strane imaju pristup vašim privatnim podacima. Razlog
        je izvršavanje zadataka koje smo im dodelili u naše ime. Ipak, u obavezi
        su da ne otkrivaju i ne koriste informacije za bilo koje druge svrhe.
      </p>
      <h2>Bezbednost</h2>
      <p>
        Cenimo što nam poveravate vaše privatne informacije, zato težimo da
        koristimo komercijalno prihvatljive načine za zaštitu vaših podataka.
        Ali zapamtite da ni jedan način prenošenja podataka preko interneta, ili
        metod elektronskog čuvanja podataka nije 100% bezbedan i pouzdan, i mi
        ne možemo garantovati apsolutnu bezbednost.
      </p>
      <h2>Veze do drugih web sajtova</h2>
      <p>
        Naš proizvod može sadržati veze ka drugim web sajtovima. Ukoliko
        kliknete na vezu ka trećoj strani, bićete usmereni na njihov web sajt.
        Zapamtite da mi ne kontrolišemo ove eksterne web sajtove. Iz tog
        razloga, savetujemo vas da pročitate Politiku privatnosti na ovim web
        sajtovima. Nemamo kontrolu i ne preuzimamo nikakvu odgovornost za
        sadržaj, politiku privatnosti, ili prakse korišćene na web sajtovima
        trećih strana.
      </p>
      <h2>Privatnost dece</h2>
      <p>
        Naš proizvod nije namenjen bilo kome ispod 13 godina starosti. Ne
        prikupljamo svesno privatne podatke dece mlađe od 13 godina. U slučaju
        da otkrijemo da nam je dete mlađe od 13 godina dostavilo privatne
        informacije, istog trenutka brišemo ove podatke sa naših servera.
        Ukoliko ste roditelj ili staratelj i svesni ste da nam je vaše dete
        dostavilo privatne informacije, molimo vas da nas kontaktirate kako bi
        preuzeli neophodne korake.
      </p>
      <h2>Izmene ove Politike privatnosti</h2>
      <p>
        Politika privatnosti se ažurira s vremena na vreme. Zbog toga,
        savetujemo vas da periodično pogledate ovu stranicu zbog mogućih izmena.
        Obavestićemo vas o izmenama postavljanjem novog dokumenta na ovu stranu.
        Izmene u dokumentu važe od momenta postavljanja na ovu stranu.
      </p>
      <h2>Kontaktirajte nas</h2>
      <p>
        Ukoliko imate bilo kakva pitanja ili sugestije u vezi naše Politike
        privatnosti, ne oklevajte i kontaktirajte nas na office@inmedia.rs
      </p>
    </>
  );
}

export default PrivacyPolicySerbian;
