import React from "react";
function PrivacyPolicyCyrilic() {
  return (
    <>
      <h1>Политика приватности</h1>
      <p>Верзија: 1, Важи од: 01.01.2023.</p>
      <p>
        Inndex Media д.о.о. Београд креирала је Индекс апликацију и интернет
        сајт у форми бесплатног дигиталног производа. Производ је бесплатно
        обезбедило предузеће Inndex Media д.о.о. Београд и намењена је за
        коришћење у виђеном стању.
      </p>
      <p>
        Ова страница служи да информише посетиоце о нашој политици везаној за
        прикупљање, коришћење и откривање приватних информација уколико неко
        одлучи да користи наш производ.
      </p>
      <p>
        Уколико одлучите да користите наш производ, самим коришћењем прихватате
        прикупљање и употребу информација у вези овог документа. Приватне
        информације које прикупљамо се користе за пружање и унапређивање нашег
        производа. Нећемо користити нити делити ваше приватне информације са
        било ким изузев како је описано у овом документу.
      </p>
      <h2>Прикупљање и коришћење информација</h2>
      <p>
        У сврху бољег корисничког искуства током коришћења нашег производа,
        можемо тражити од вас да нам доставите поједине приватне податке,
        укључујући али не ограничавајући се на ваше име, презиме и вашу мејл
        адресу. Информације које тражимо биће задржане са наше стране и
        коришћене како је описано у овом документу.
      </p>
      <p>
        Апликација и интернет сајт користе услуге трећих страна које могу
        прикупљати информације које вас могу идентификовати.
      </p>
      <div>
        <p>
          Везе до Политика приватности трећих страна чије услуге користе
          апликација и интернет сајт.
        </p>
        <ul>
          <li>
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Google Play услуге
            </a>
          </li>
          <li>
            <a
              href="https://mapsplatform.google.com/"
              target="_blank"
              rel="noreferrer"
            >
              Google Maps платформа
            </a>
          </li>
        </ul>
      </div>
      <h2>Лог подаци</h2>
      <p>
        Желимо да вас обавестимо да када год користите наш производ, у случају
        грешке током коришћења, прикупљамо податке и информације (користећи
        производе трећих страна) са уређаја који користите који се називају Лог
        подаци. Лог подаци могу садржати информације као што су Internet
        Protocol (IP) адреса вашег уређаја, назив уређаја, верзија оперативног
        система, конфигурацију приликом коришћења нашег производа, време и датум
        коришћења производа, и друге статистичке податке.
      </p>
      <h2>Колачићи (Cookies)</h2>
      <p>
        Колачићи су датотеке са малом количином информација које се уобичајено
        користе као јединствени идентификатори. Они се шаљу вашем претраживачу
        од стране интернет сајтова које посећујете и чувају се у интерној
        меморији вашег уређаја.
      </p>
      <p>
        Наш производ не користи колачиће експлицитно. Ипак, производ може
        користити код или библиотеке трећих страна које користе колачиће за
        прикупљање информација и унапређивање њихових услуга. Имате могућност да
        прихватите или одбијете ове колачиће, као и да будете упознати када је
        колачић послат на ваш уређај. Уколико одлучите да одбијете колачиће,
        постоји могућност да нећете моћи да користите делове нашег производа или
        комплетан производ.
      </p>
      <h2>Пружаоци услуга</h2>
      <p>
        Можемо ангажовати трећу страну, односно компанију или појединца из
        следећих разлога:
      </p>
      <ul>
        <li>Да олакшају коришћење нашег производа;</li>
        <li>Да обезбеде услугу у наше име;</li>
        <li>Да пруже услуге у вези производа; или</li>
        <li>Да нам помогну у анализи начина коришћења нашег производа.</li>
      </ul>
      <p>
        Желимо да обавестимо кориснике нашег производа да трећа страна
        ангажована са наше стране имају приступ вашим приватним подацима. Разлог
        је извршавање задатака које смо им доделили у наше име. Ипак, у обавези
        су да не откривају и не користе информације за било које друге сврхе.
      </p>
      <h2>Безбедност</h2>
      <p>
        Ценимо што нам поверавате ваше приватне информације, зато тежимо да
        користимо комерцијално прихватљиве начине за заштиту ваших података. Али
        запамтите да ни један начин преношења података преко интернета, или
        метод електронског чувања података није 100% безбедан и поуздан, и ми не
        можемо гарантовати апсолутну безбедност.
      </p>
      <h2>Везе до других интернет сајтова</h2>
      <p>
        Наш производ може садржати везе ка другим интернет сајтовима. Уколико
        кликнете на везу ка трећој страни, бићете усмерени на њихов интернет
        сајт. Запамтите да ми не контролишемо ове екстерне интернет сајтове. Из
        тог разлога, саветујемо вас да прочитате Политику приватности на овим
        интернет сајтовима. Немамо контролу и не преузимамо никакву одговорност
        за садржај, политику приватности, или праксе коришћене на интернет
        сајтовима трећих страна.
      </p>
      <h2>Приватност деце</h2>
      <p>
        Наш производ није намењен било коме испод 13 година старости. Не
        прикупљамо свесно приватне податке деце млађе од 13 година. У случају да
        откријемо да нам је дете млађе од 13 година доставило приватне
        информације, истог тренутка бришемо ове податке са наших сервера.
        Уколико сте родитељ или старатељ и свесни сте да нам је ваше дете
        доставило приватне информације, молимо вас да нас контактирате како би
        преузели неопходне кораке.
      </p>
      <h2>Измене ове Политике приватности</h2>
      <p>
        Политика приватности се ажурира с времена на време. Због тога,
        саветујемо вас да периодично погледате ову страницу због могућих измена.
        Обавестићемо вас о изменама постављањем новог документа на ову страну.
        Измене у документу важе од момента постављања на ову страну.
      </p>
      <h2>Контактирајте нас</h2>
      <p>
        Уколико имате било каква питања или сугестије у вези наше Политике
        приватности, не оклевајте и контактирајте нас на office@inmedia.rs
      </p>
    </>
  );
}

export default PrivacyPolicyCyrilic;
